// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400%3b0,700%3b1,400%3b1,700&display=swap');
@import url('https://fonts.bunny.net/css?family=cabin:400,400i,700,700i');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons";
@import '~jquery-ui/themes/base/all.css';
