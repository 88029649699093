// Body
$body-bg: #f8f8f8;
$primary: #32406b;
$secondary: #e6e6e6;
$success: #30a789;
$info: #ffeeca;
$warning: #AC7835;
$danger: #C52132;
$light: #f8f9fa;
$dark: #0b0f22;
$medicine: #194D45;
$problem: #484E70;
$disease: #BD443D;
$cold: #6c757d;
$follow: #8b2751;

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "medicine": $medicine,
    "problem": $problem,
    "disease": $disease,
    "cold": $cold,
    "follow": $follow,
) !default;

// Typography
$font-family-sans-serif: 'Cabin', sans-serif;
$font-size-base: 0.90rem;
$line-height-base: 1.6;
